* {
  box-sizing: border-box;
  margin: 0px;
}

button {
  border: none;
  outline: none;
  border-radius: 5px;
}

button:hover {
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
    border: none !important;
}